import { Injectable, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { 
	connect, 
	createLocalVideoTrack, 
	LocalTrack,
	LocalVideoTrack,
	LocalAudioTrack,
	Participant,
    RemoteTrack,
    RemoteAudioTrack,
    RemoteVideoTrack,
    RemoteParticipant,
    RemoteTrackPublication,
	Room
} from 'twilio-video';

@Injectable({
  providedIn: 'root'
})

export class VideoService {
	remoteVideo: ElementRef;
	localVideo: ElementRef;
	previewing: boolean;
	roomObj: any;
	
	private participants: Map<Participant.SID, RemoteParticipant>;	
	private renderer: Renderer2;
	private emitAloneSource = new Subject<any>();
	
    changeEmitted$ = this.emitAloneSource.asObservable();
	
    emitAlone(change: any) {
        this.emitAloneSource.next(change);
    }
	
	get participantCount() {
        return !!this.participants ? this.participants.size : 0;
    }

    get isAlone() {
        return this.participantCount === 0;
    }
	
	constructor(
		private httpClient: HttpClient,
		private router: Router,
		private rendererFactory: RendererFactory2
	){
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	getData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/videodata', data);
	}

	getToken(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/videocall', data);
	}
	
	mute() {
		this.roomObj.localParticipant.audioTracks.forEach(function(audioTrack){
			audioTrack.track.disable();
		});
	}

	unmute() {
		this.roomObj.localParticipant.audioTracks.forEach(function(audioTrack){
			audioTrack.track.enable();
		});
	}
	
	preview() {
		this.roomObj.localParticipant.videoTracks.forEach(function(videoTrack){
			videoTrack.track.disable();
		});
	}

	unpreview() {
		this.roomObj.localParticipant.videoTracks.forEach(function(videoTrack){
			videoTrack.track.enable();
		});
	}
	
	connectToRoom(accessToken: string, options): void {
		connect(accessToken, options).then(room => {
			this.roomObj = room;
			
			if (this.localVideo && this.localVideo.nativeElement) {
				this.attachLocalTrack();
				this.previewing = true;
			}
			
			this.participants = room.participants;
			if (this.participants) {
				this.participants.forEach(participant => this.registerParticipantEvents(participant));
			}
			
			room
			.on('disconnected', (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
			.on('participantConnected', (participant: RemoteParticipant) => this.add(participant))
            .on('participantDisconnected', (participant: RemoteParticipant) => this.remove(participant));
		}, (error) => {
			alert(error.message);
		});
	}
	
	disconnect() {
        if (this.roomObj) {
            this.roomObj.disconnect();
            this.roomObj = null;
        }

        this.participants.clear();
		this.emitAlone(this.isAlone);
    }
	
	attachLocalTrack(): void {
		this.roomObj.localParticipant.videoTracks.forEach(publication => {
			const element = publication.track.attach();
			this.renderer.data.id = publication.track.sid;
			this.renderer.setStyle(element, 'width', '100%');
			this.renderer.setStyle(element, 'height', '100%');
			this.renderer.appendChild(this.localVideo.nativeElement, element);
		})
		
		this.emitAlone(this.isAlone);
	}

	detachLocalTrack(track: LocalTrack) {
        if (this.isLocalDetachable(track)) {
            track.detach().forEach(el => el.remove());
        }
		
		this.emitAlone(this.isAlone);
    }

    isLocalDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track && ((track as LocalAudioTrack).detach !== undefined || (track as LocalVideoTrack).detach !== undefined);
    }
	
	registerParticipantEvents(participant: RemoteParticipant) {
        if (participant) {
            participant.tracks.forEach(publication => this.subscribe(publication));
            participant.on('trackPublished', publication => this.subscribe(publication));
            participant.on('trackUnpublished', publication => {
				if (publication && publication.track) {
					this.detachRemoteTrack(publication.track);
				}
			});
        }
    }
	
	add(participant: RemoteParticipant) {
        if (this.participants && participant) {
            this.participants.set(participant.sid, participant);
            this.registerParticipantEvents(participant);
        }
		
		this.emitAlone(this.isAlone);
    }

    remove(participant: RemoteParticipant) {
        if (this.participants && this.participants.has(participant.sid)) {
            this.participants.delete(participant.sid);
        }
		
		this.emitAlone(this.isAlone);
    }
	
	subscribe(publication: RemoteTrackPublication | any) {
        if (publication && publication.on) {
            publication.on('subscribed', (track: RemoteTrack) => this.attachRemoteTrack(track));
            publication.on('unsubscribed', (track: RemoteTrack) => this.detachRemoteTrack(track));
        }
    }

    attachRemoteTrack(track: RemoteTrack) {
        if (this.isRemoteAttachable(track)) {
            const element = track.attach();
            this.renderer.data.id = track.sid;
            this.renderer.setStyle(element, 'width', '95%');
            this.renderer.setStyle(element, 'height', '95%');
            this.renderer.appendChild(this.remoteVideo.nativeElement, element);
        }
		
		this.emitAlone(this.isAlone);
    }

    detachRemoteTrack(track: RemoteTrack) {
        if (this.isRemoteDetachable(track)) {
            track.detach().forEach(el => el.remove());
        }
		
		this.emitAlone(this.isAlone);
    }

    isRemoteAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track && ((track as RemoteAudioTrack).attach !== undefined || (track as RemoteVideoTrack).attach !== undefined);
    }

    isRemoteDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track && ((track as RemoteAudioTrack).detach !== undefined || (track as RemoteVideoTrack).detach !== undefined);
    }
}