import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class User {
	name: String;
	password: String;
}

@Injectable({
	providedIn: 'root'
})

export class AuthService {
	constructor(private http: HttpClient) { }

	login(user: User): Observable<any> {
		return this.http.post<any>(environment.apiurl+'auth/login', user);
	}
	
	guard(data: any): Observable<any> {
		 return this.http.post<any>(environment.apiurl+'auth/guard', data);
	}
}
