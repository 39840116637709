import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { Layout3Component } from './layout3/layout3.component';
import { AuthGuardService } from "../service/auth-guard.service";

const routes: Routes = [ 
	{
		path: '',
		component: Layout2Component,
		children: [
			{ path: '', canActivate: [AuthGuardService], loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
			{ path: 'patients', canActivate: [AuthGuardService], loadChildren: () => import('src/app/patients/list/patients-list.module').then(m => m.PatientsListModule) },
			{ path: 'patients/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/patients/action/patients-action.module').then(m => m.PatientsActionModule) },
			{ path: 'patients/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/patients/action/patients-action.module').then(m => m.PatientsActionModule) },
			{ path: 'patients/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/patients/view/patients-view.module').then(m => m.PatientsViewModule) },
			
			{ path: 'prescription', canActivate: [AuthGuardService], loadChildren: () => import('src/app/prescription/list/prescription-list.module').then(m => m.PrescriptionListModule) },
			{ path: 'prescription/view/:patientid/:id/:type', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/prescriptionletter/view/prescriptionletter-view.module').then(m => m.PrescriptionletterViewModule) },

			{ path: 'contacts/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/contacts/action/contacts-action.module').then(m => m.ContactsActionModule) },
			{ path: 'contacts/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/contacts/action/contacts-action.module').then(m => m.ContactsActionModule) },
			{ path: 'contacts/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/contacts/view/contacts-view.module').then(m => m.ContactsViewModule) },

			{ path: 'patientsfiles/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/action/files-action.module').then(m => m.FilesActionModule) },
			{ path: 'patientsfiles/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/action/files-action.module').then(m => m.FilesActionModule) },
			{ path: 'patientsfiles/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/view/files-view.module').then(m => m.FilesViewModule) },

			{ path: 'patientsappointments/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/action/appointments-action.module').then(m => m.AppointmentsActionModule) },
			{ path: 'patientsappointments/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/action/appointments-action.module').then(m => m.AppointmentsActionModule) },
			{ path: 'patientsappointments/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/view/appointments-view.module').then(m => m.AppointmentsViewModule) },

			{ path: 'patientsinitialnote/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/initialnote/action/initialnote-action.module').then(m => m.InitialnoteActionModule) },
			{ path: 'patientsinitialnote/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/initialnote/action/initialnote-action.module').then(m => m.InitialnoteActionModule) },
			{ path: 'patientsinitialnote/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/initialnote/view/initialnote-view.module').then(m => m.InitialnoteViewModule) },

			{ path: 'patientsfollowupnote/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/followupnote/action/followupnote-action.module').then(m => m.FollowupnoteActionModule) },
			{ path: 'patientsfollowupnote/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/followupnote/action/followupnote-action.module').then(m => m.FollowupnoteActionModule) },
			{ path: 'patientsfollowupnote/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/followupnote/view/followupnote-view.module').then(m => m.FollowupnoteViewModule) },

			{ path: 'patientsvitalsnote/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/vitalsnote/action/vitalsnote-action.module').then(m => m.VitalsnoteActionModule) },
			{ path: 'patientsvitalsnote/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/vitalsnote/action/vitalsnote-action.module').then(m => m.VitalsnoteActionModule) },
			{ path: 'patientsvitalsnote/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/vitalsnote/view/vitalsnote-view.module').then(m => m.VitalsnoteViewModule) },

			{ path: 'patientssmartnote/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/smartnote/action/smartnote-action.module').then(m => m.SmartnoteActionModule) },
			{ path: 'patientssmartnote/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/smartnote/action/smartnote-action.module').then(m => m.SmartnoteActionModule) },
			{ path: 'patientssmartnote/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/smartnote/view/smartnote-view.module').then(m => m.SmartnoteViewModule) },

			{ path: 'patientstreatment/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/treatmentnote/action/treatmentnote-action.module').then(m => m.TreatmentnoteActionModule) },
			{ path: 'patientstreatment/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/treatmentnote/action/treatmentnote-action.module').then(m => m.TreatmentnoteActionModule) },
			{ path: 'patientstreatment/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/treatmentnote/view/treatmentnote-view.module').then(m => m.TreatmentnoteViewModule) },

			{ path: 'patientsprescriptionletter/view/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/prescriptionletter/view/prescriptionletter-view.module').then(m => m.PrescriptionletterViewModule) },
			{ path: 'patientsprescriptionletter/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/prescriptionletter/view/prescriptionletter-view.module').then(m => m.PrescriptionletterViewModule) },
			{ path: 'patientsprescriptionletter/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/prescriptionletter/action/prescriptionletter-action.module').then(m => m.PrescriptionletterActionModule) },
			{ path: 'patientsprescriptionletter/history/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/note/prescriptionletter/history/prescriptionletter-history.module').then(m => m.PrescriptionletterHistoryModule) },

			{ path: 'patientstasks/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/action/tasks-action.module').then(m => m.TasksActionModule) },
			{ path: 'patientstasks/action/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/action/tasks-action.module').then(m => m.TasksActionModule) },
			{ path: 'patientstasks/view/:patientid/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/view/tasks-view.module').then(m => m.TasksViewModule) },

			{ path: 'patientsinvoices/action/:patientid/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsinvoices/action/:patientid/:id/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsinvoices/view/:patientid/:id/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/view/invoices-view.module').then(m => m.InvoicesViewModule) },

			{ path: 'patientsfinvoices/action/:patientid/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsfinvoices/action/:patientid/:id/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsfinvoices/view/:patientid/:id/:tab', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/view/invoices-view.module').then(m => m.InvoicesViewModule) },

			{ path: 'patientsfcreditnote/action/:patientid/:tab/:creditnote', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsfcreditnote/action/:patientid/:id/:tab/:creditnote', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsfcreditnote/view/:patientid/:id/:tab/:creditnote', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/view/invoices-view.module').then(m => m.InvoicesViewModule) },

			{ path: 'patientsfrefund/action/:patientid/:id/:tab/:refund', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'patientsfrefund/view/:patientid/:id/:tab/:refund', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/view/invoices-view.module').then(m => m.InvoicesViewModule) },

			{ path: 'appointments', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/list/appointments-list.module').then(m => m.AppointmentsListModule) },
			{ path: 'appointments/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/action/appointments-action.module').then(m => m.AppointmentsActionModule) },
			{ path: 'appointments/action/:doctorid/:date/:time', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/action/appointments-action.module').then(m => m.AppointmentsActionModule) },
			{ path: 'appointments/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/action/appointments-action.module').then(m => m.AppointmentsActionModule) },
			{ path: 'appointments/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/view/appointments-view.module').then(m => m.AppointmentsViewModule) },
			{ path: 'appointments/calendar', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/calendar/appointments-calendar.module').then(m => m.AppointmentsCalendarModule) },

			{ path: 'tasks', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/list/tasks-list.module').then(m => m.TasksListModule) },
			{ path: 'opentasks', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/list/tasks-list.module').then(m => m.TasksListModule) },
			{ path: 'closedtasks', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/list/tasks-list.module').then(m => m.TasksListModule) },
			{ path: 'tasks/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/action/tasks-action.module').then(m => m.TasksActionModule) },
			{ path: 'tasks/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/action/tasks-action.module').then(m => m.TasksActionModule) },
			{ path: 'tasks/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/tasks/view/tasks-view.module').then(m => m.TasksViewModule) },

			{ path: 'productscategories', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/productscategories/list/productscategories-list.module').then(m => m.ProductscategoriesListModule) },
			{ path: 'productscategories/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/productscategories/action/productscategories-action.module').then(m => m.ProductscategoriesActionModule) },
			{ path: 'productscategories/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/productscategories/action/productscategories-action.module').then(m => m.ProductscategoriesActionModule) },

			{ path: 'products', canActivate: [AuthGuardService], loadChildren: () => import('src/app/products/list/products-list.module').then(m => m.ProductsListModule) },
			{ path: 'products/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/products/action/products-action.module').then(m => m.ProductsActionModule) },
			{ path: 'products/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/products/action/products-action.module').then(m => m.ProductsActionModule) },
			{ path: 'products/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/products/view/products-view.module').then(m => m.ProductsViewModule) },

			{ path: 'files', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/list/files-list.module').then(m => m.FilesListModule) },
			{ path: 'files/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/action/files-action.module').then(m => m.FilesActionModule) },
			{ path: 'files/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/action/files-action.module').then(m => m.FilesActionModule) },
			{ path: 'files/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/files/view/files-view.module').then(m => m.FilesViewModule) },

			{ path: 'invoices', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/list/invoices-list.module').then(m => m.InvoicesListModule) },
			{ path: 'invoices/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'invoices/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },
			{ path: 'invoices/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/view/invoices-view.module').then(m => m.InvoicesViewModule) },

			{ path: 'payments', canActivate: [AuthGuardService], loadChildren: () => import('src/app/payments/list/payments-list.module').then(m => m.PaymentsListModule) },
			{ path: 'payments/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/payments/view/payments-view.module').then(m => m.PaymentsViewModule) },

			{ path: 'reportsdashboard', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/reportsdashboard/view/reportsdashboard-view.module').then(m => m.ReportsdashboardViewModule) },
			{ path: 'appointmentsschedule', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/appointmentsschedule/list/appointmentsschedule-list.module').then(m => m.AppointmentsscheduleListModule) },
			{ path: 'missedappointments', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/missedappointments/list/missedappointments-list.module').then(m => m.MissedappointmentsListModule) },
			{ path: 'marketingappointments', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/marketingappointments/list/marketingappointments-list.module').then(m => m.MarketingappointmentsListModule) },
			{ path: 'marketingreferral', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/marketingreferral/list/marketingreferral-list.module').then(m => m.MarketingreferralListModule) },
			{ path: 'patientsinvoice', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/patientsinvoice/list/patientsinvoice-list.module').then(m => m.PatientsinvoiceListModule) },
			{ path: 'medicationsprescribed', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/medicationsprescribed/list/medicationsprescribed-list.module').then(m => m.MedicationsprescribedListModule) },
			{ path: 'appointmentsattended', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/appointmentsattended/list/appointmentsattended-list.module').then(m => m.AppointmentsattendedListModule) },
			{ path: 'csvreports', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/csvreports/list/csvreports-list.module').then(m => m.CsvreportsListModule) },
			{ path: 'salesreports', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/list/salesreports-list.module').then(m => m.SalesreportsListModule) },

			{ path: 'communication', canActivate: [AuthGuardService], loadChildren: () => import('src/app/communication/list/communication-list.module').then(m => m.CommunicationListModule) },
			{ path: 'communication/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/communication/action/communication-action.module').then(m => m.CommunicationActionModule) },

			{ path: 'pos', canActivate: [AuthGuardService], loadChildren: () => import('src/app/pos/view/pos-view.module').then(m => m.PosViewModule) },

			{ path: 'clients', canActivate: [AuthGuardService], loadChildren: () => import('src/app/clients/list/clients-list.module').then(m => m.ClientsListModule) },
			{ path: 'clients/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/clients/action/clients-action.module').then(m => m.ClientsActionModule) },
			{ path: 'clients/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/clients/action/clients-action.module').then(m => m.ClientsActionModule) },
			{ path: 'clients/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/clients/view/clients-view.module').then(m => m.ClientsViewModule) },

			{ path: 'settings', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/settings/view/settings-view.module').then(m => m.SettingsViewModule) },

			{ path: 'company/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/company/action/company-action.module').then(m => m.CompanyActionModule) },
			{ path: 'company/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/company/action/company-action.module').then(m => m.CompanyActionModule) },
			{ path: 'company/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/company/view/company-view.module').then(m => m.CompanyViewModule) },
			
			{ path: 'tasksdashboard', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/tasksdashboard/view/tasksdashboard-view.module').then(m => m.TasksdashboardViewModule) },

			{ path: 'users', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/users/list/users-list.module').then(m => m.UsersListModule) },
			{ path: 'users/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/users/action/users-action.module').then(m => m.UsersActionModule) },
			{ path: 'users/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/users/action/users-action.module').then(m => m.UsersActionModule) },
			{ path: 'users/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/users/view/users-view.module').then(m => m.UsersViewModule) },

			{ path: 'roles', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/roles/list/roles-list.module').then(m => m.RolesListModule) },
			{ path: 'roles/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/roles/action/roles-action.module').then(m => m.RolesActionModule) },
			{ path: 'roles/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/roles/action/roles-action.module').then(m => m.RolesActionModule) },
			{ path: 'roles/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/roles/view/roles-view.module').then(m => m.RolesViewModule) },

			{ path: 'tax', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/tax/list/tax-list.module').then(m => m.TaxListModule) },
			{ path: 'tax/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/tax/action/tax-action.module').then(m => m.TaxActionModule) },
			{ path: 'tax/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/tax/action/tax-action.module').then(m => m.TaxActionModule) },
			{ path: 'tax/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/tax/view/tax-view.module').then(m => m.TaxViewModule) },

			{ path: 'currency', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/currency/list/currency-list.module').then(m => m.CurrencyListModule) },
			{ path: 'currency/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/currency/action/currency-action.module').then(m => m.CurrencyActionModule) },
			{ path: 'currency/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/currency/action/currency-action.module').then(m => m.CurrencyActionModule) },
			{ path: 'currency/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/currency/view/currency-view.module').then(m => m.CurrencyViewModule) },

			{ path: 'servicescategories', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/servicescategories/list/servicescategories-list.module').then(m => m.ServicescategoriesListModule) },
			{ path: 'servicescategories/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/servicescategories/action/servicescategories-action.module').then(m => m.ServicescategoriesActionModule) },
			{ path: 'servicescategories/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/servicescategories/action/servicescategories-action.module').then(m => m.ServicescategoriesActionModule) },

			{ path: 'services', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/services/list/services-list.module').then(m => m.ServicesListModule) },
			{ path: 'services/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/services/action/services-action.module').then(m => m.ServicesActionModule) },
			{ path: 'services/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/services/action/services-action.module').then(m => m.ServicesActionModule) },
			{ path: 'services/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/services/view/services-view.module').then(m => m.ServicesViewModule) },

			{ path: 'paymentmethods', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/paymentmethods/list/paymentmethods-list.module').then(m => m.PaymentmethodsListModule) },
			{ path: 'paymentmethods/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/paymentmethods/action/paymentmethods-action.module').then(m => m.PaymentmethodsActionModule) },
			{ path: 'paymentmethods/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/paymentmethods/action/paymentmethods-action.module').then(m => m.PaymentmethodsActionModule) },
			{ path: 'paymentmethods/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/paymentmethods/view/paymentmethods-view.module').then(m => m.PaymentmethodsViewModule) },

			{ path: 'branch', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/branch/list/branch-list.module').then(m => m.BranchListModule) },
			{ path: 'branch/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/branch/action/branch-action.module').then(m => m.BranchActionModule) },
			{ path: 'branch/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/branch/action/branch-action.module').then(m => m.BranchActionModule) },
			{ path: 'branch/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/branch/view/branch-view.module').then(m => m.BranchViewModule) },

			{ path: 'rooms', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/rooms/list/rooms-list.module').then(m => m.RoomsListModule) },
			{ path: 'rooms/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/rooms/action/rooms-action.module').then(m => m.RoomsActionModule) },
			{ path: 'rooms/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/rooms/action/rooms-action.module').then(m => m.RoomsActionModule) },
			{ path: 'rooms/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/rooms/view/rooms-view.module').then(m => m.RoomsViewModule) },

			{ path: 'smartnoteheadings', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smartnoteheadings/list/smartnoteheadings-list.module').then(m => m.SmartnoteheadingsListModule) },
			{ path: 'smartnoteheadings/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smartnoteheadings/action/smartnoteheadings-action.module').then(m => m.SmartnoteheadingsActionModule) },
			{ path: 'smartnoteheadings/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smartnoteheadings/action/smartnoteheadings-action.module').then(m => m.SmartnoteheadingsActionModule) },
			{ path: 'smartnoteheadings/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smartnoteheadings/view/smartnoteheadings-view.module').then(m => m.SmartnoteheadingsViewModule) },

			{ path: 'questionnaires', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/questionnaires/list/questionnaires-list.module').then(m => m.QuestionnairesListModule) },
			{ path: 'questionnaires/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/questionnaires/action/questionnaires-action.module').then(m => m.QuestionnairesActionModule) },
			{ path: 'questionnaires/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/questionnaires/action/questionnaires-action.module').then(m => m.QuestionnairesActionModule) },
			{ path: 'questionnaires/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/questionnaires/view/questionnaires-view.module').then(m => m.QuestionnairesViewModule) },

			{ path: 'bodychart', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/bodychart/list/bodychart-list.module').then(m => m.BodychartListModule) },
			{ path: 'bodychart/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/bodychart/action/bodychart-action.module').then(m => m.BodychartActionModule) },
			{ path: 'bodychart/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/bodychart/action/bodychart-action.module').then(m => m.BodychartActionModule) },

			{ path: 'smsscheduler', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smsscheduler/list/smsscheduler-list.module').then(m => m.SmsschedulerListModule) },
			{ path: 'smsscheduler/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smsscheduler/action/smsscheduler-action.module').then(m => m.SmsschedulerActionModule) },
			{ path: 'smsscheduler/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smsscheduler/action/smsscheduler-action.module').then(m => m.SmsschedulerActionModule) },
			
			{ path: 'medications', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/medications/list/medications-list.module').then(m => m.MedicationsListModule) },
			{ path: 'medications/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/medications/action/medications-action.module').then(m => m.MedicationsActionModule) },
			{ path: 'medications/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/medications/action/medications-action.module').then(m => m.MedicationsActionModule) },
			{ path: 'medications/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/medications/view/medications-view.module').then(m => m.MedicationsViewModule) },

			{ path: 'trashedusers', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/trashedusers/list/trashedusers-list.module').then(m => m.TrashedusersListModule) },
			
			{ path: 'urlsettings', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/urlsettings/list/urlsettings-list.module').then(m => m.UrlSettingsListModule) },

			{ path: 'importpatients', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/importpatients/action/importpatients-action.module').then(m => m.ImportpatientsActionModule) },

			{ path: 'template', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/template/list/template-list.module').then(m => m.TemplateListModule) },
			{ path: 'template/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/template/action/template-action.module').then(m => m.TemplateActionModule) },
			{ path: 'template/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/template/action/template-action.module').then(m => m.TemplateActionModule) },

			{ path: 'medicalreport', canActivate: [AuthGuardService], loadChildren: () => import('src/app/medicalreport/list/medicalreport-list.module').then(m => m.MedicalreportListModule) },
			{ path: 'medicalreport/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/medicalreport/action/medicalreport-action.module').then(m => m.MedicalreportActionModule) },
			{ path: 'medicalreport/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/medicalreport/action/medicalreport-action.module').then(m => m.MedicalreportActionModule) },
			{ path: 'medicalreport/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/medicalreport/view/medicalreport-view.module').then(m => m.MedicalreportViewModule) },
			{ path: 'share-medicalreport/view/:code', loadChildren: () => import('src/app/medicalreport/view/medicalreport-view.module').then(m => m.MedicalreportViewModule) },
			{ path: 'medicalreport/invoice/action/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/invoices/action/invoices-action.module').then(m => m.InvoicesActionModule) },

			{ path: 'medicalreporttags', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/medicalreporttag/view/medicalreporttag-view.module').then(m => m.MedicalreporttagViewModule) },

			{ path: 'test-kits', canActivate: [AuthGuardService], loadChildren: () => import('src/app/kits/view/kits-view.module').then(m => m.KitsViewModule) },
			{ path: 'test-kits/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/kits/action/kits-action.module').then(m => m.KitsActionModule) },
			{ path: 'test-kits/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/kits/action/kits-action.module').then(m => m.KitsActionModule) },
			{ path: 'test-kits/lab-result-reports', canActivate: [AuthGuardService], loadChildren: () => import('src/app/kits/labresult/kit-result.module').then(m => m.KitResultModule) },

			{ path: 'kits-serials/:id/:patientid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/kits/assignkit/assign-kit.module').then(m => m.AssignKitModule) },
			{ path: 'kit-settings', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/kitsettings/view/kitsettings-view.module').then(m => m.KitsettingsViewModule) },
			{ path: 'kit-settings/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/kitsettings/action/kitsettings-action.module').then(m => m.KitsettingsActionModule) },
			{ path: 'kit-settings/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/kitsettings/action/kitsettings-action.module').then(m => m.KitsettingsActionModule) },

			{ path: 'notification', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/notification/list/notification-list.module').then(m => m.NotificationListModule) },
			{ path: 'notification/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/notification/action/notification-action.module').then(m => m.NotificationActionModule) },
			{ path: 'notification/action/:id/:channeltype', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/notification/action/notification-action.module').then(m => m.NotificationActionModule) },
			{ path: 'notification/view/:id/:channeltype', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/notification/view/notification-view.module').then(m => m.NotificationViewModule) },
			
			{ path: 'smscredit', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smscredit/list/smscredit-list.module').then(m => m.SmscreditListModule) },
		
			{ path: 'smscreditcompany', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/smscreditcompany/list/smscreditcompany-list.module').then(m => m.SmscreditcompanyListModule) },
			
			{ path: 'pharmacy', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/pharmacy/list/pharmacy-list.module').then(m => m.PharmacyListModule) },
			{ path: 'pharmacy/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/pharmacy/action/pharmacy-action.module').then(m => m.PharmacyActionModule) },
			{ path: 'pharmacy/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/pharmacy/action/pharmacy-action.module').then(m => m.PharmacyActionModule) },
			{ path: 'pharmacy/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/pharmacy/view/pharmacy-view.module').then(m => m.PharmacyViewModule) },
			
			{ path: 'onlinebooking', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/onlinebooking/view/onlinebooking-view.module').then(m => m.OnlinebookingViewModule) },

			{ path: 'pettycash', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/pettycash/list/pettycash-list.module').then(m => m.PettycashListModule) },
      		{ path: 'pettycash/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/pettycash/action/pettycash-action.module').then(m => m.PettycashActionModule) },
      		{ path: 'pettycash/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/pettycash/action/pettycash-action.module').then(m => m.PettycashActionModule) },
      		{ path: 'pettycash/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/pettycash/view/pettycash-view.module').then(m => m.PettycashViewModule) },

			{ path: 'packages', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/packages/list/packages-list.module').then(m => m.PackagesListModule) },
			{ path: 'packages/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/packages/action/packages-action.module').then(m => m.PackagesActionModule) },
			{ path: 'packages/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/packages/action/packages-action.module').then(m => m.PackagesActionModule) },
			{ path: 'packages/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/settings/packages/view/packages-view.module').then(m => m.PackagesViewModule) },
			
			{ path: 'cashmanagment', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/cashmanagment/list/cashmanagment-list.module').then(m => m.CashManagmentListModule) },
			{ path: 'cashmanagment/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/cashmanagment/action/cashmanagment-action.module').then(m => m.CashManagmentActionModule) },
			{ path: 'cashmanagment/action/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/cashmanagment/action/cashmanagment-action.module').then(m => m.CashManagmentActionModule) },
			{ path: 'cashmanagment/view/:id', canActivate: [AuthGuardService], loadChildren: () => import('src/app/reports/salesreports/pages/cashmanagment/view/cashmanagment-view.module').then(m => m.CashManagmentViewModule) },
			
			{ path: 'profile', canActivate: [AuthGuardService], loadChildren: () => import('src/app/profile/view/profile-view.module').then(m => m.ProfileViewModule) },
			{ path: 'profile/action', canActivate: [AuthGuardService], loadChildren: () => import('src/app/profile/action/profile-action.module').then(m => m.ProfileActionModule) },

			{ path: 'logout', canActivate: [AuthGuardService], loadChildren: () => import('src/app/logout/logout.module').then(m => m.LogoutModule) }
		]
	},
	{
		path: '',
		component: Layout1Component,
		children: [
			{ path: 'login', loadChildren: () => import('src/app/login/login.module').then(m => m.LoginModule) },
			{ path: 'register', loadChildren: () => import('src/app/register/register.module').then(m => m.RegisterModule) },
			{ path: 'forgotpassword', loadChildren: () => import('src/app/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule) },
			{ path: 'forms/register-kit', loadChildren: () => import('src/app/kits/registerkit/register-kit.module').then(m => m.RegisterKitModule) },
			{ path: 'videoappointment/:id', loadChildren: () => import('src/app/appointments/video/appointments-video.module').then(m => m.AppointmentsVideoModule) },
			{ path: 'changepassword/:id', loadChildren: () => import('src/app/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },
		] 
	},
	{
		path: '',
		component: Layout3Component,
		children: [
			{ path: 'sharemedicalreport/:id/:expiry', loadChildren: () => import('src/app/medicalreport/share/medicalreport-share.module').then(m => m.MedicalreportShareModule) },
			{ path: 'patients/selfregister/:uuid', loadChildren: () => import('src/app/patients/pages/selfregister/patients-selfregister.module').then(m => m.PatientsSelfregisterModule) },
			{ path: 'appointments/book/:companyUid', loadChildren: () => import('src/app/appointments/public-booking/appointments-public-booking.module').then(m => m.AppointmentsPublicBookingModule) },
			{ path: 'appointments/book/:patientid/:companyUid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/public-booking/appointments-public-booking.module').then(m => m.AppointmentsPublicBookingModule) },
			{ path: 'appointments/quickbook/:companyUid', loadChildren: () => import('src/app/appointments/quick-public-booking/appointments-quick-public-booking.module').then(m => m.AppointmentsQuickPublicBookingModule) },
			{ path: 'appointments/quickbook/:patientid/:companyUid', canActivate: [AuthGuardService], loadChildren: () => import('src/app/appointments/quick-public-booking/appointments-quick-public-booking.module').then(m => m.AppointmentsQuickPublicBookingModule) },
			{ path: 'appointments/embedbook/:companyUid/:embedid', loadChildren: () => import('src/app/appointments/public-booking/appointments-public-booking.module').then(m => m.AppointmentsPublicBookingModule) },
			{ path: 's/c/:id', loadChildren: () => import('src/app/patients/pages/selfregister/patients-selfregister.module').then(m => m.PatientsSelfregisterModule) },
			{ path: 's/i/:encryptid', loadChildren: () => import('src/app/invoices/pages/invoicesshare/invoicesshare.module').then(m => m.InvoicesShareModule) },
			{ path: 'p/i/:paymentid/:encryptid', loadChildren: () => import('src/app/invoices/pages/invoicesshare/invoicesshare.module').then(m => m.InvoicesShareModule) },
			{ path: 's/tv/:encryptid', loadChildren: () => import('src/app/note/pages/treatmentnoteshare/view/treatmentnoteshare.module').then(m => m.TreatmentnoteShareModule) },
			{ path: 's/ta/:encryptpatientid/:encrypttemplateid', loadChildren: () => import('src/app/note/pages/treatmentnoteshare/action/treatmentnoteshare.module').then(m => m.TreatmentnoteShareModule) },
			{ path: 's/p/:encryptid', loadChildren: () => import('src/app/note/pages/prescriptionlettershare/prescriptionlettershare.module').then(m => m.PrescriptionletterShareModule) },
		]
	},
	{ path: '**', redirectTo: '/' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})

export class LayoutRoutingModule { }
