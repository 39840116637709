import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HttpcancelService {

    private pendingHTTPRequests$ = new Subject<void>();

	constructor() { }

	public cancelPendingRequests() {
		this.pendingHTTPRequests$.next();
	}

	public onCancelPendingRequests() {
		return this.pendingHTTPRequests$.asObservable();
	}

}