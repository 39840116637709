import { Component } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Videoemit } from '../../service/videoemit.service';
import { environment } from '../../../environments/environment';

export enum Roles {
  RootAdmin 		= '1',
  CompanyOwner 		= '2',
  PractitionerPlus 	= '3',
  Practitioner 		= '4',
  Staff 			= '5',
  Patient 			= '6'
}

@Component({
	selector: 'app-layout2',
	templateUrl: './layout2.component.html'
})

export class Layout2Component{
	role:any;
	userId:any;
	appointmentoptin:any;
	user:any;
	invoiceoptin:any;
	windowwidth:any;
	videowrapper:any = '0';
	appointmentid:any = '';
	profileimage:any = '';

	menu = {
		'Dashboard' 	:	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff, Roles.Patient],
		'Patients' 		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'Appointments'	: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff, Roles.Patient],
		'Prescription' 	: 	[Roles.Patient],
		'Results' 		: 	[Roles.Patient],
		'Files'			: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'Invoices'		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff, Roles.Patient],
		'Payments'		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'Reports'		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.Staff],
		'Products'		: 	[Roles.RootAdmin, Roles.CompanyOwner],
		'Communication'	: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'POS'			: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'Settings'		: 	[Roles.RootAdmin, Roles.CompanyOwner],
		'MedicalReport'	: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
		'Clients'		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff],
    	'Tasks'			: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Staff],
		'Profile'		: 	[Roles.RootAdmin, Roles.CompanyOwner, Roles.PractitionerPlus, Roles.Practitioner, Roles.Staff, Roles.Patient]
	};

	constructor(
		public commonService: CommonService,
		public videoemit: Videoemit,
	) {
		this.videoemit.changeEmitted$.subscribe(data => {
			this.videowrapper = '1';
			this.appointmentid = data;
        });

		var storage = JSON.parse(localStorage.getItem('minivideo') || '{}');
		if(storage['appointmentid'] && this.appointmentid=='') {
			this.videowrapper = '1';
			this.appointmentid = storage['appointmentid'];
		}
	}

	videodisconnect(event){
		this.videowrapper = '0';
		this.appointmentid = '';
	}

	userdetails(event){
		if (this.commonService.userDetail) {
			if(event.userdetailsoutlet)
			event.userdetailsoutlet(this.commonService.userDetail);
		} else {
			this.commonService.getUserDetail().subscribe(
				result => {
					if(result.success){
						this.commonService.userDetail = result.success;
						this.user = result.success;
						this.windowwidth = window.innerWidth;
						this.role = environment.role2[result.success.role];
						this.userId = result.success.id;
						this.appointmentoptin = result.success.appointmentoptin;
						this.invoiceoptin = result.success.invoiceoptin;
						event.userdetailsoutlet ? event?.userdetailsoutlet(result.success) : null;
						if(result.success.profileimage!=null) this.profileimage = environment.s3Bucket+result.success.profileimage;
					}
				}
			);
		}
	}

	isVisible(pageName) {
		if (!this.role) return false;
		return this.menu[pageName].find(x => x == this.role);
	}
}
