import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from "../service/auth.service";
import {CommonService} from "./common.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

	constructor(
		public authService: AuthService,
		public router: Router,
		public commonService: CommonService,
	) {}

	canActivate(): boolean {
    if (!localStorage.getItem('auth_token'))
    {
      this.tokenExpired();
      return false;
    }
		this.authService.guard({id : localStorage.getItem('auth_token')}).subscribe(
			res => {
				if(!res){
					this.tokenExpired();
					return false;
				}
			},
			error => {
				this.tokenExpired();


				return false;
			},
			() => {}
		);

		return true;
	}

  tokenExpired()
  {
    if (this.commonService)
      this.commonService.userDetail = null;

    localStorage.removeItem('auth_token');
    this.router.navigate(['login']);
  }

}
