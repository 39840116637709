import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonChatComponent } from './common-chat.component';
import { FormsModule } from '@angular/forms';


@NgModule({
	declarations: [
		CommonChatComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule
	],
	exports: [
		CommonChatComponent
	]
})

export class CommonChatModule { }
