import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-layout1',
	templateUrl: './layout1.component.html'
})

export class Layout1Component implements OnInit {
	constructor() { }
	ngOnInit(): void { }
}
