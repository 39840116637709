import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, UserUpdateReason, Conversation, ConversationUpdateReason, Client, Message, Paginator, Participant } from "@twilio/conversations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import Swal from "sweetalert2";

import { CommonService } from '../../service/common.service';
import { AppointmentsService } from '../../service/appointments.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-common-chat',
	templateUrl: './common-chat.component.html'
})


export class CommonChatComponent implements OnInit {
	@Input() apptstatus: Subject<boolean>;

	chatForm: FormGroup;
	s3Bucket: any;
	@Input() companyid: any;
	@Input() userid: any;
	@Input() apptstatusdata: any;

	user:any;
	contact:any;
	chatmsg: any;

	token: any;
	chatbox: any = 0;
	chatready: any = 0;

	textInput: string = '';
	messages: any[] = [];
	sendermessages: any[] = [];

	onlineusers: any[] = [];
	offlineusers: any[] = [];
	convmemberidentity: any[] = [];
	chatmembersdata: any[] = [];
	channelid: any;
	chattoken: any;
	chatdata: any;
	appointmentid: any;

	client:any;
	msgauthor:any;
	conversation:any;
	chatClient:Client;
	currentTime;
	membername: any;
	owneridentity: any;
	memberidentity: any;
	fullname: any;
	chatidentity: any;
	chatname: any;
	chatmessages: any;
	lastmsg: any;
	currentconv: any;
	onlineuserlist: any;
	presentconv: any;
	msgindex:any;
	doctorname:any;
	patientname:any;
	progress:any;
	unreadmessage:any;
	uniquename:any;
	channeldata:any = '';
	tokendata:any = '';
	chatmembers:any = '';
	connectstatus: boolean = false;
	state: boolean = false;
	
	constructor(
		public fb: FormBuilder,
		private toastrService: ToastrService,
		public commonService: CommonService,
		public appointmentsService: AppointmentsService
	) {}	

	ngOnInit(): void {
		this.currentTime = new Date();
		this.s3Bucket = environment.s3Bucket;
		
		// sessionStorage.removeItem("chatchannel");
		// sessionStorage.removeItem("chattoken");
		// sessionStorage.removeItem("chatmembers");

		this.channeldata = sessionStorage.getItem("chatchannel");
		this.tokendata = sessionStorage.getItem("chattoken");
		this.chatmembers = sessionStorage.getItem("chatmembers");

		if(this.channeldata!='' && this.channeldata!=null) this.channelid = JSON.parse(this.channeldata);

		if(this.tokendata!='' && this.tokendata!=null) this.chattoken = JSON.parse(this.tokendata).chattoken;
		if(this.tokendata!='' && this.tokendata!=null) this.owneridentity = JSON.parse(this.tokendata).owneridentity;

		if(this.channeldata!=null && this.channeldata!=''){
			this.connectionToken(this.chattoken);
			JSON.parse(this.chatmembers).forEach(identity => {
				this.memberidentity = identity;
				this.convmemberidentity.push(identity);
			});
		}

		if(this.tokendata==null || this.tokendata==''){
			this.commonService.getChatChannel({}).subscribe(
				result => {
					this.channelid = result.success;

					sessionStorage.setItem("chatchannel", JSON.stringify(this.channelid));
					this.channeldata = sessionStorage.getItem("chatchannel");

					this.commonService.getChatToken({}).subscribe( result => {
						this.chattoken = result.result.token;
						this.connectionToken(this.chattoken);
						this.owneridentity = result.result.identity;
						
						let chatdata:any = { 'chattoken': this.chattoken, 'owneridentity': this.owneridentity }
						sessionStorage.setItem("chattoken", JSON.stringify(chatdata));
						this.tokendata = sessionStorage.getItem("chattoken");

						result.member.forEach(data => {
							this.memberidentity = data.identity;
							this.convmemberidentity.push(data.identity);
							
							sessionStorage.setItem("chatmembers", JSON.stringify(this.convmemberidentity));
							this.chatmembers = sessionStorage.getItem("chatmembers");

							this.fullname = data.fullname;
						});
					});

				}
			)
		}

		this.chatForm = this.fb.group({
			chatmsg: ['', Validators.required],
		})

	}

	async connectionToken(token){
		if(this.connectstatus==false) this.client = new Client(token);
		this.connectstatus = true;

		this.client.on("conversationUpdated", ({conversation, updateReasons}: {
			conversation: Conversation,
			updateReasons: ConversationUpdateReason[]
		}) => {
			// console.log(conversation);
			// console.log(updateReasons);
		});

		this.client.on("userUpdated", ({ user, updateReasons}: {
			user: User,
			updateReasons: UserUpdateReason[]
		}) => {
			if (updateReasons.includes("reachabilityOnline")) {
				this.onlineuserlist = user;
				this.onlineStatus();
				if(this.state == false) this.userNotifiable();
			}
		})

		this.presentconv = await this.client.getConversationByUniqueName(this.channelid.uname);

		this.presentconv.on("participantJoined", (participant: Participant) => {
					this.getOnlineParticipants();
				});
		
		this.getOnlineParticipants();

		this.convmemberidentity.forEach(async(result) => {
			await this.createConversation(result);
		});
	}

	async getOnlineParticipants(){
		if(this.channelid.sid == this.presentconv.sid){
			this.currentconv = this.presentconv;
			let participants: Participant[] = await this.presentconv.getParticipants();

			participants.forEach(async(result)=>{
				const user: User = await result.getUser();
				this.onlineuserlist = user;

				this.onlineStatus();
			})
		}
	}

	async createConversation(identity){
		this.messages = [];
		
		this.generateUname(identity, 1);

		try{
			this.conversation = await this.client.createConversation({uniqueName: this.uniquename});
			this.conversation.add(identity, {attributeKey: identity});
			this.conversation.join();
		}catch{		
			this.conversation = await this.client.getConversationByUniqueName(this.uniquename);
		}

		this.messageAdded(this.conversation);
		
		this.getMessage(this.conversation);
		
		let participants: Participant[] = await this.conversation.getParticipants();
		participants.forEach(result=> {
			this.membername = result.identity;
		})
	}

	messageAdded(conversation){
		conversation.on('messageAdded', msg =>{
			if(this.owneridentity != msg.author && this.msgindex != msg.index){
				this.onBeforeOpen(msg);
				this.unreadMessage(conversation);

				var message = msg.body.split(",");
				if(this.owneridentity==message[0]){
					this.showSwal(this.owneridentity, message[1], message[2],msg.author);	
				}
				if(this.owneridentity==message[1]){
					this.doctorSwal(this.owneridentity,message[0]);
				} 

				this.msgindex = msg.index;
				this.lastmsg = msg.body;
				this.msgauthor = msg.author;
				this.messages.push(this.addFromReceiver(msg.body, new Date()));
				if(this.chatready!=2 && this.owneridentity!=message[0] && this.owneridentity!=message[1]) this.showToaster(msg.body, msg.author);
			}

			if(this.chatready==2){
				setTimeout(() => {
					this.scrollToBottom();
				}, 700);
			}
		});
	}

	onBeforeOpen(e) {
      	let audio: HTMLAudioElement = new Audio('../../../assets/sound/message_tone.mp3');
    	audio.play();
    }

	async generateUname(identity, type){
		var identity_arr = [identity, this.owneridentity];
		identity_arr.sort();
		this.uniquename = identity_arr[0] + identity_arr[1];

		if(type==2){
			this.presentconv = await this.client.getConversationByUniqueName(this.uniquename);
			let participants: Participant[] = await this.presentconv.getParticipants();

			participants.forEach(async(result)=>{
				const user: User = await result.getUser();
				const onlineuser = user.isOnline;
				if(this.memberidentity==user.identity && onlineuser==true) await this.presentconv.sendMessage(type);
			})
		}

		if(type==3){
			const getmsgconv = await this.client.getConversationByUniqueName(this.uniquename);
			this.messageAdded(getmsgconv);
			this.getMessage(getmsgconv);
		}

		if(typeof type== 'string'){
			const getmsgconv = await this.client.getConversationByUniqueName(this.uniquename);
			var msg = identity + "," + type + "," + this.appointmentid;
        	getmsgconv.sendMessage(msg);
			this.messageAdded(getmsgconv);
		}

		if(type==4 || type==5){
			const getmsgconv = await this.client.getConversationByUniqueName(this.uniquename);
			var msg ='';
			if(type==4) msg = "Send in,"+identity;
			if(type==5) msg = "Don't send in,"+identity;
        	getmsgconv.sendMessage(msg);
			this.messageAdded(getmsgconv);
		}
	}	

	async getMessage(conversation){
		const msg = await conversation.getMessages();
		await this.unreadMessage(conversation);

		msg.items.forEach(result => {
			if(result.author == this.owneridentity){
				this.messages.push(this.addFromSender(result.body, result.dateCreated));
			} 
			
			if(result.author != this.owneridentity){
				this.chatmessages = result.body;
				this.lastmsg = result.body;
				this.messages.push(this.addFromReceiver(result.body, result.dateCreated));
			}
		});
	}

	async unreadMessage(conversation){
		this.unreadmessage = await conversation.getUnreadMessagesCount();
	}

	onlineStatus(){
		if(this.onlineuserlist.isOnline==false && this.onlineusers.includes(this.onlineuserlist.identity)){
			if(this.onlineusers.includes(this.onlineuserlist.identity)) {
				this.onlineusers.splice(this.onlineusers.indexOf(this.onlineuserlist.identity), 1)
				return;
			}
		}
		
		if (this.onlineuserlist.isOnline==true) {
			if(this.owneridentity!=this.onlineuserlist.identity) {
				if(!this.onlineusers.includes(this.onlineuserlist.identity)) {
					this.generateUname(this.onlineuserlist.identity, 3);
					this.onlineusers.push(this.onlineuserlist.identity);
					return;
				}
			}
		}
	}

	userNotifiable(){
		this.state = true;
		this.apptstatus.subscribe(v => { 
			this.apptstatusdata.forEach(result => {
				this.doctorname = result.doctor.username;
				this.patientname = result.user.firstname + result.user.lastname;
				this.progress = result.progress;
				this.appointmentid = result.id;
			});
			if(this.progress=="arrived") this.generateUname(this.doctorname, this.patientname);
		});
	}

	showSwal(doctor, patientname, id, author){
		if(this.owneridentity == doctor){
			Swal.fire({
				text: 'Your Patient Mr/Mrs '+ patientname + ' has arrived',
				icon: 'info',
				showDenyButton:true,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Send in',
				denyButtonColor: '#cf0d0d',
				denyButtonText: 'Ignore'
			}).then((result) => {
			  if (result.isConfirmed) {
			  	this.appointmentid = id;
			  	this.generateUname(author, 4);
			   	this.appointmentsService.changeprogress({'id': id, 'progress': 'send_in'}).subscribe(
					result => {
						if(result.success){
							this.apptstatusdata = result.success;
							setTimeout(() => {
								this.apptstatus.next(true);
							}, 1000);
						}
					},
					error => {
						this.toastrService.error('Try Later.', 'Error', {timeOut: 3000});
					},
					() => {
						this.toastrService.success('Appointment progress submitted successfully.', 'Success', {timeOut: 3000});
					}
				);
			  } else if (result.isDenied) {
			  	//cancel
			    this.generateUname(author, 5);
			  }
			})
		}
	}

	doctorSwal(doctor, message){
		if(this.owneridentity == doctor){
			Swal.fire({
				text: message,
				icon: 'info',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Done',
			})
		}
	}

	chatBox(event, type){
		if(type==1){
			if(this.chatready==1 || this.chatready==2){
				this.chatready = 0;
				this.chatbox = 0;
			} 
			else{
				this.chatready = 1;
				this.chatbox = 1;
			}
		}
	}

	async chat(event, type){
		if(type==1){
			this.chatready = 1;
			type = 0;
		}
		else if(type==2){
			this.chatidentity = event.target.id;
			this.chatname = event.target.textContent;
			await this.createConversation(this.chatidentity);
			this.chatready = 2;

			setTimeout(() => {
				this.scrollToBottom();
			}, 1000);

			type = 0;
			
		} else {
			if(this.chatready!=2){
				this.chatidentity = event;
				await this.createConversation(event);
				this.chatready = 2;
				this.chatbox = 1;

				setTimeout(() => {
					this.scrollToBottom();
				}, 1000);
			}

			type = 0;
		}
	}

	msg(user, contact, message, timestamp = new Date()) {
		return {
			user,
			contact,
			message,
			timestamp
		};
	}
	
	addFromSender(msg, chatmsg) {
		return this.msg(this.memberidentity, this.owneridentity, msg, chatmsg);
	}

	addFromReceiver(msg, chatmsg) {
		return this.msg(this.owneridentity, this.memberidentity, msg, chatmsg);
	}
	
	async inputStatus(event){
		let messagesPaginator: Paginator<Message> = await this.conversation.getMessages();
        const messages: Message[] = messagesPaginator.items;

		await this.conversation.setAllMessagesRead();
		this.unreadMessage(this.conversation);
		
		if(messages[0] && messages[0].index) await this.conversation.updateLastReadMessageIndex(messages[0].index);
	}

	showToaster(msg, author) {
		this.toastrService.success(msg, author, {timeOut: 5000})
			.onTap
			.subscribe(() => this.toasterClickedHandler(author));
	}

	async toasterClickedHandler(author) {
		this.chat(author, 3);
	}

	async onSubmit() {
        if (this.chatForm.value.chatmsg == '') return;
        this.messages.push(this.addFromSender(this.chatForm.value.chatmsg, new Date()));    
        this.conversation.sendMessage(this.chatForm.value.chatmsg);
        this.chatForm.reset();

		this.scrollToBottom();
    }

	async scrollToBottom(){
		const element:any = (<HTMLInputElement>document.getElementById("scrollBottom"));
   		element.scrollTop = element.scrollHeight;

		await this.conversation.setAllMessagesRead();
		this.unreadMessage(this.conversation);
	}
}


