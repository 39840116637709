import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { HttpcancelService } from './httpcancel.service';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class HttpcancelInterceptor implements HttpInterceptor {

    constructor(
		router: Router,
        private httpcancelService: HttpcancelService
	){
		router.events.subscribe(event => {
			if (event instanceof ActivationEnd) {
				this.httpcancelService.cancelPendingRequests();
			}
		});
	}

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		let originalurl = req.url;
		let searchurl = originalurl.indexOf("api/");
		
		if(['auth/guard', 'common/getUserDetail', 'common/getCurrenciesList', 'company/fileupload'].includes(originalurl.substr(searchurl+4))){
			return next.handle(req);
		}

		return next.handle(req).pipe(takeUntil(this.httpcancelService.onCancelPendingRequests()))
    }
}
