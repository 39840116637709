import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { Layout3Component } from './layout3/layout3.component';
import { LayoutRoutingModule } from './layout-routing.module';

import { LoaderService } from '../service/loader.service';
import { LoaderInterceptor } from '../service/loaderinterceptor.service';
import { LoaderComponent } from '../loader/loader.component';

import { AppointmentvideoComponent } from '../appointments/pages/appointmentvideo/appointmentvideo.component';
import { CommonChatModule } from '../common/chat/common-chat.module';

@NgModule({
	declarations: [
		Layout1Component,
		Layout2Component,
		Layout3Component,
		LoaderComponent,
		AppointmentvideoComponent,
	],
	imports: [
		HttpClientModule,
		CommonModule,
		ReactiveFormsModule,
		LayoutRoutingModule,
		CommonChatModule
	],
	providers: [
		LoaderService,
		{ 
			provide: HTTP_INTERCEPTORS, 
			useClass: LoaderInterceptor, 
			multi: true 
		}
	]
})

export class LayoutModule { }
