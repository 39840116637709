import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './service/auth.interceptor';
import { HttpcancelService } from './service/httpcancel.service';
import { HttpcancelInterceptor } from './service/httpcancelinterceptor.service';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot([]),
		ToastrModule.forRoot(),
		LayoutModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide : LocationStrategy ,
			useClass: HashLocationStrategy
		},
		HttpcancelService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpcancelInterceptor, multi: true },
		DatePipe
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
