import { Component } from '@angular/core';

@Component({
	selector: 'app-layout3',
	templateUrl: './layout3.component.html'
})

export class Layout3Component{
	constructor() {}
}
