import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
	constructor(private httpClient: HttpClient) { }
	
	userDetail;

	getDoctorsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getDoctorsList', data);
	}

	getAvailableDoctorsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getAvailableDoctorsList', data);
	}
	
	getAvailableDoctorsStaffList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getAvailableDoctorsStaffList', data);
	}
	
	getUsernameDobList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getUsernameDobList', data);
	}

	getFilesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getFilesList', data);
	}

	getInvoicesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getInvoicesList', data);
	}

	getPrescriptionLetterList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPrescriptionLetterList', data);
	}

	getTreatmentNoteList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getTreatmentNoteList', data);
	}

	getRolesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getRolesList', data);
	}

	getServicesCategoriesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getServicesCategoriesList', data);
	}

	getServicesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getServicesList', data);
	}

	getProductsCategoriesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getProductsCategoriesList', data);
	}

	getProductsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getProductsList', data);
	}

	getTaxesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getTaxesList', data);
	}

	getCurrenciesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getCurrenciesList', data);
	}

	getMedicationsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getMedicationsList', data);
	}

	getTemplatesList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getTemplatesList', data);
	}

	getBodyChartList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getBodyChartList', data);
	}

	getBranchList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getBranchList', data);
	}
	
	getSmartNoteHeadingList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getSmartNoteHeadingList', data);
	}

	getPharmacyList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPharmacyList', data);
	}
	
	getEmbedUrlList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getEmbedUrlList', data);
	}
	
	getEmailTemplateList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getEmailTemplateList', data);
	}
	
	getSmsTemplateList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getSmsTemplateList', data);
	}
	
	getClientsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getClientsList', data);
	}
	
	getUserDetail(): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getUserDetail', {});
	}
	
	getPaymentMethodByCompany(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPaymentMethodByCompany', data);
	}
	
	getPatientAppointment(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPatientAppointment', data);
	}

	getDoctorsByService(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getDoctorsByService', data);
	}

	getServicesByDoctor(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getServicesByDoctor', data);
	}

	getCompanyByUuid(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getCompanyByUuid', data);
	}

	getServicesCategoriesByUuid(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getServicesCategoriesByUuid', data);
	}

	getServicesByUuid(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getServicesByUuid', data);
	}

	getDoctorsByUuid(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getDoctorsByUuid', data);
	}

	getTemplatesById(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getTemplatesById', data);
	}

	getSmsBalance(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getSmsBalance', data);
	}

	emailSmsContent(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/emailSmsContent', data);
	}

	checkEmailExpiry(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/checkEmailExpiry', data);
	}

	getKitsList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getKitsList', data);
	}

	getCompany(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getCompany', data);
	}	
	
	getPaymentmethodList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPaymentmethodList', data);
	}	
	
	getPaymenttotal(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPaymenttotal', data);
	}	
	
	getPackageList(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPackageList', data);
	}
	
	getPackageListItems(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getPackageListItems', data);
	}
	
	getChatToken(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getChatToken', data);
	}
	
	getChatChannel(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getChatChannel', data);
	}
	
	getWizardstatus(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'common/getWizardstatus', data);
	}
}
