import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter } from '@angular/core';
import { VideoService } from '../../../service/video.service';
import { environment } from '../../../../environments/environment';

const enum Status {
	OFF = 0,
	RESIZE = 1,
	MOVE = 2
}

@Component({
	selector: 'app-appointmentvideo-page',
	templateUrl: './appointmentvideo.component.html',
	styleUrls: ['./appointmentvideo.component.scss']
})

export class AppointmentvideoComponent implements OnInit, AfterViewInit {
	@Input('width') public width: number;
	@Input('height') public height: number;
	@Input('left') public left: number;
	@Input('top') public top: number;
	@Input('role') public role: any;
	@Input('appointmentid') public appointmentid: any;
	@ViewChild("box") public box: ElementRef;
	private boxPosition: { left: number, top: number };
	private containerPos: { left: number, top: number, right: number, bottom: number };
	public mouse: {x: number, y: number}
	public status: Status = Status.OFF;
	private mouseClick: {x: number, y: number, left: number, top: number}
	
	videoflag: string = '0';
	appointmentdata: any;
	logo: any;
	alone: boolean;
	speaker: any = '1';
	camera: any = '1';
	@ViewChild('localVideo') localVideo: ElementRef;
	@ViewChild('remoteVideo') remoteVideo: ElementRef;
	
	@Output() videoend = new EventEmitter<any>();

	constructor(
		public videoService: VideoService
	) {}
	
	ngOnInit() {
		this.videoService.changeEmitted$.subscribe(data => {
			this.alone = data;
        });
	}

	ngAfterViewInit(){
		this.loadBox();
		this.loadContainer();
		this.connect();
	}
	
	connect() {
		if(this.appointmentid!=''){
			var minivideo = JSON.parse(localStorage.getItem('minivideo') || '{}');
			if(minivideo['speaker'] || minivideo['camera']){
				this.speaker = minivideo['speaker'];
				this.camera = minivideo['camera'];
			}
			
			this.videoService.getData({'encryptid' : this.appointmentid}).subscribe(result => {
				this.appointmentdata = result.success;
				this.appointmentdata.description = result.success.description.replace(/<.*?>/g, '');
				this.logo = environment.s3Bucket+result.success.company.logo;
			});
			
			this.videoService.getToken({id : this.appointmentid}).subscribe(result => {
				if(result.success){
					this.videoflag = '1';

					this.videoService.localVideo = this.localVideo;
					this.videoService.remoteVideo = this.remoteVideo;
					var resulttoken = result.success.token;
					var resultidentity = result.success.identity;
					var _this = this;
					
					localStorage.setItem('minivideo', JSON.stringify({'appointmentid' : this.appointmentid, 'speaker' : this.speaker, 'camera' : this.camera}));
					navigator.mediaDevices.enumerateDevices().then(devices => {
						var devicestatus:any = [];
						devices.forEach(device => {
							if(device.kind == 'audioinput' && device.label) devicestatus.push('1');
							if(device.kind == 'videoinput' && device.label) devicestatus.push('2');
						})
						
						devicestatus = devicestatus.filter((x, i, a) => a.indexOf(x) == i);
						if(devicestatus.includes('1') && devicestatus.includes('2')){
							_this.videoService.connectToRoom(resulttoken, { name: resultidentity, audio: true, video: { width: 240 } })
						}else if(devicestatus.includes('1') && !devicestatus.includes('2')){
							_this.videoService.connectToRoom(resulttoken, { name: resultidentity, audio: true, video: false })
						}else if(!devicestatus.includes('1') && devicestatus.includes('2')){
							_this.videoService.connectToRoom(resulttoken, { name: resultidentity, audio: false, video: { width: 240 } })
						}
					});
					if(this.speaker=='0') this.mute();
				}
			});
		}
	}
	
	mute() { 
		this.videoService.mute(); 
		this.speaker = '0';
		localStorage.setItem('minivideo', JSON.stringify({'appointmentid' : this.appointmentid, 'speaker' : '0', 'camera' : this.camera}));
	}

	unmute() { 
		this.videoService.unmute();
		this.speaker = '1';
		localStorage.setItem('minivideo', JSON.stringify({'appointmentid' : this.appointmentid, 'speaker' : '1', 'camera' : this.camera}));
	}
	
	preview() { 
		this.videoService.preview(); 
		this.camera = '0';
		localStorage.setItem('minivideo', JSON.stringify({'appointmentid' : this.appointmentid, 'speaker' : this.speaker, 'camera' : '0'}));
	}

	unpreview() { 
		this.videoService.unpreview();
		this.camera = '1';
		localStorage.setItem('minivideo', JSON.stringify({'appointmentid' : this.appointmentid, 'speaker' : this.speaker, 'camera' : '1'}));
	}
	
	ngOnDestroy() { 
		this.endcall();
	}
	
	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander(event) {
		this.endcall();
	}
	
	endcall() { 
		localStorage.removeItem('minivideo');
		this.videoService.disconnect();
		this.videoend.emit();
	}
	
	private loadBox(){
		const {left, top} = this.box.nativeElement.getBoundingClientRect();
		this.boxPosition = {left, top};
	}

	private loadContainer(){
		const left = this.boxPosition.left - this.left;
		const top = this.boxPosition.top - this.top;
		const right = left + window.innerWidth;
		const bottom = top + window.innerHeight;
		this.containerPos = { left, top, right, bottom };
	}

	setStatus(event: MouseEvent, status: number){
		if(status === 1) event.stopPropagation();
		else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
		else this.loadBox();
		this.status = status;
	}

	@HostListener('window:mousemove', ['$event'])
	onMouseMove(event: MouseEvent){
		this.mouse = { x: event.clientX, y: event.clientY };

		if(this.status === Status.RESIZE) this.resize();
		else if(this.status === Status.MOVE) this.move();
	}

	private resize(){
		if(this.resizeCondMeet()){
			this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
			this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
		}
	}

	private resizeCondMeet(){
		return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
	}

	private move(){
		if(this.moveCondMeet()){
			this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
			this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
		}
	}

	private moveCondMeet(){
		const offsetLeft = this.mouseClick.x - this.boxPosition.left; 
		const offsetRight = this.width - offsetLeft; 
		const offsetTop = this.mouseClick.y - this.boxPosition.top;
		const offsetBottom = this.height - offsetTop;
		
		return (
			this.mouse.x > this.containerPos.left + offsetLeft && 
			this.mouse.x < this.containerPos.right - offsetRight &&
			this.mouse.y > this.containerPos.top + offsetTop &&
			this.mouse.y < this.containerPos.bottom - offsetBottom
		);
	}
}
