<app-common-chat 
	[companyid]="user?.companydetailid" 
	[userid]="user?.id"
></app-common-chat>
<div class="hold-transition sidebar-mini">
	<div class="wrapper">
		<nav class="main-header navbar navbar-expand navbar-white navbar-light">
			<a routerLink="dashboard" class="brand-link">
				<img src="/assets/images/hn-logo-03.png" style="width:150px" />
			</a>
			<div class="sidebar overflow-auto">
				<nav>
					<ul class="nav nav-pills nav-sidebar flex-row flex-nowrap" data-widget="treeview" role="menu"
						data-accordion="false">
						<li class="nav-item" *ngIf="isVisible('Patients')">
							<a routerLink="patients" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Patients</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Files')">
							<a routerLink="files" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Documents</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Appointments')">
							<a routerLink="{{role!='6' ? 'appointments/calendar' : 'appointments'}}" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Appointments</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Patients')">
							<a routerLink="/tasks" class="nav-link"[attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Tasks</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('POS')">
							<a routerLink="pos" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>POS</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Invoices') && (role!='6' || (role=='6' && invoiceoptin=='1'))">
							<a routerLink="invoices" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Invoices</p>
							</a>
						</li>
						<!--<li class="nav-item" *ngIf="isVisible('Payments')">
							<a routerLink="payments" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Payments</p>
							</a>
						</li>-->
						<li class="nav-item" *ngIf="isVisible('Reports')">
							<a routerLink="reportsdashboard" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Analytics</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Communication')">
							<a routerLink="communication" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Communication</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('MedicalReport')">
							<a routerLink="medicalreport" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Medical Report</p>
							</a>
						</li>
						<!--<li class="nav-item" *ngIf="isVisible('Clients')">
							<a routerLink="clients" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Clients</p>
							</a>
						</li>-->
						<li class="nav-item" *ngIf="isVisible('Settings')">
							<a routerLink="settings" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Settings</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Prescription')">
							<a routerLink="prescription" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Prescription</p>
							</a>
						</li>
						<li class="nav-item" *ngIf="isVisible('Results')">
							<a routerLink="files" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Results</p>
							</a>
						</li>
						<li class="nav-item">
							<a routerLink="profile" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Profile</p>
							</a>
						</li>
						<li class="nav-item">
							<a routerLink="logout" class="nav-link" [attr.data-widget]="windowwidth < 991 ? 'pushmenu' : ''">
								<p>Logout</p>
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<ul class="navbar-nav ml-auto">
				<li class="dropdown user user-menu">
					<a class="nav-link d-flex align-items-center" routerLink="profile" style=" color: white;">
						<img *ngIf="profileimage" class="pro_nav_img mx-2" src="{{profileimage}}" alt="">
						<i *ngIf="!profileimage" class="mx-2 fas fa-user-circle display-5"></i>
						<span class="hidden-xs">{{user?.fullname}} </span>
					</a>
				</li>
			</ul>
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
				</li>
			</ul>
		</nav>
		<div *ngIf="videowrapper=='1'">
			<app-appointmentvideo-page [width]="0" [height]="0" [left]="200" [top]="100" [role]="role" [appointmentid]="appointmentid" (videoend)="videodisconnect($event)"></app-appointmentvideo-page>
		</div>
		<app-loader></app-loader>
		<router-outlet (activate)="userdetails($event)"></router-outlet>
	</div>
</div>
