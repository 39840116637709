<div class="content-wrapper">
	<section class="content">
		<div class="chat-container">
			<div class="chat-icon" (click)="chatBox($event, 1)">
				<i class="fas fa-comment-alt"></i>
			</div>
			<div class="chat-on" *ngIf="chatbox==1">
				<div class="chat-list" *ngIf="chatready==1">
					<button class="user-list"><i class="far fa-user"></i></button>
					<p>Online Users</p>
				</div>
				<div class="chat-list" *ngIf="chatready==2">
					<button class="back-list" (click)="chat($event, 1)"><i class="fas fa-caret-left"></i></button>
					<p>Conversation</p>														
				</div>
				<div class="chat">
					<div class="online-list" *ngIf="chatready==1">
						<p class="mb-0 font-weight-bold" *ngIf="onlineusers.length==0">No Online Users</p>
						<div class="online-user" (click)="chat($event, 2)" *ngFor="let users of onlineusers" id="{{ users }}">
							<div class="d-flex align-items-center" id="{{ users }}">
								<img src="{{ users.doctor_profile_image ? s3Bucket + users.doctor_profile_image : 'assets/images/avatar-gbf419404f_640.png' }}"
									alt="User" id="{{ users }}">
								<div class="online-text mx-4">
									<p class="online-chatter" id="{{ users }}">{{ users }}</p>
									<p class="history-chat" *ngIf="msgauthor == users" id="{{ users }}">{{ lastmsg }}</p>
								</div>
							</div>
							<p class="chat-notification mb-0" *ngIf="msgauthor == users && unreadmessage!=0 && unreadmessage!=undefined" id="{{ users }}">{{ unreadmessage }}</p>
						</div>
					</div>
					<ng-container *ngIf="chatready==2">
						<div class="chat-preview">
							<div class="chat-head">
								<img src="assets/images/avatar-gbf419404f_640.png" alt="" class="chat-avatar">
								<p class="sender">{{ chatidentity }}</p>
							</div>
							<div class="chat-area" id="scrollBottom">
								<div class="chat-messages">
									<div class="chat-message" *ngFor="let message of messages" 
									[class.user-message]="message.user === memberidentity"
        							[class.sender-message]="message.user === owneridentity">
										<div class="message-text">
											{{ message.message }}
										</div>
										<p class="chat-time">{{message.timestamp | date: "E hh:mm a"}}</p>
									</div>
								</div>
							</div>
							<form [formGroup]="chatForm" (ngSubmit)="onSubmit()">
								<div class="chat-input">
									<input type="text" formControlName="chatmsg" (focus)="inputStatus($event)" placeholder="Reply here..." class="chat-input-box">
									<button class="chat-btn" type="submit"><i class="far fa-paper-plane"></i></button>
								</div>
							</form>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</section>
</div>


