import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class Videoemit {
    private emitChangeSource = new Subject<any>();
	
    changeEmitted$ = this.emitChangeSource.asObservable();
	
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }
}