import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AppointmentsService {
	constructor(private httpClient: HttpClient) { }

	action(data): Observable<any> {
		if(data.actionid==''){
			return this.httpClient.post(environment.apiurl+'appointments/create', data);
		}else{
			return this.httpClient.post(environment.apiurl+'appointments/edit', data);
		}
	}

	getData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/getData', data);
	}
	
	sendApptReminder(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/sendapptreminder', data);
	}
	
	delete(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/delete', data);
	}

	reschedule(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/reschedule', data);
	}

	doctorCalendarEvents(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/doctorCalendarEvents', data);
	}

	getFullCalendarData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/getFullCalendarData', data);
	}

	changeprogress(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/changeprogress', data);
	}

	changestatus(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/changestatus', data);
	}

	onlineBooking(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'appointments/onlineBooking', data);
	}
}
