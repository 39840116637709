
<div #box class="resizable-draggable" 
    [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
    [class.active]="status === 1 || status === 2"
    (mousedown)="setStatus($event, 2)"
    (window:mouseup)="setStatus($event, 0)"
	style="display:inline-block;position: absolute;z-index:99999"
>
	<div *ngIf="role=='2'" class="doctor_cam_base">
		<!-- Doctor CAM -->
		<div class="cam__base">
			<div class="d-flex align-items-center justify-content-between px-3 py-2">
				<p class="cuser__name mb-1">{{ this.appointmentdata?.service?.name }} with {{ this.appointmentdata?.doctor.firstname }}</p>
				<div class="row px-3 align-items-center">
					<p class="mb-0 px-3 cam__time">{{ this.appointmentdata?.from }}</p>
					<a href="javascript:void(0);" (click)="endcall()"><i class="cam__cancel fas fa-times"></i></a>
				</div>
			</div>
			<div class="d-flex px-3 align-items-center justify-content-between">
				<div class="pw_cmsg" [hidden]="!alone"><p class="pwc_cmsg"><i class="fas fa-clock mx-1"></i> Waiting for patient</p></div>
				<div id="remote" #remoteVideo [hidden]="alone"></div>
				<div class="cams_video">
					<div style="display:none;" #remoteVideo></div>
					<div class="cam__svideo" #localVideo></div>
				</div>
			</div>
			<div class="d-flex px-3 justify-content-end">
				<button class="cam__btn" (click)="mute()" *ngIf="speaker=='1'"><i class="cam__icon fas fa-microphone"></i></button>
				<button class="cam__btn" (click)="unmute()" *ngIf="speaker=='0'"><i class="cam__icon fas fa-microphone-slash"></i></button>
				<button class="cam__btn" (click)="preview()" *ngIf="camera=='1'"><i class="cam__icon fas fa-video"></i></button>
				<button class="cam__btn" (click)="unpreview()" *ngIf="camera=='0'"><i class="cam__icon fas fa-video-slash"></i></button>
				<button class="cam__btn" (click)="endcall()"><i class="fas fa-phone-slash mx-1"></i> End call</button>
			</div>
		</div>
	</div>


	<div *ngIf="role=='6'" class="patient_cam_base">
		<!-- Patient cam -->
		<div class="patient__cam">
			<div class="pcam_head d-flex align-items-center justify-content-between">
				<img class="h2l_logo" src="{{ logo }}" />
				<button class="pca_info"><i class="fas fa-info-circle mx-1"></i> info</button>
			</div>
			<div class="pwcam_video_panel" [hidden]="!alone"><p class="pwc_msg"><i class="fas fa-clock mx-1"></i> Waiting for clinician...</p></div>
			<div class="pcam_video_panel" #remoteVideo [hidden]="alone"></div>
			<div class="d-flex justify-content-center">
				<div class="pcam_svideo" #localVideo></div>
			</div>
			<div class="d-flex px-3 justify-content-end">
				<button class="cam__btn" (click)="mute()" *ngIf="speaker=='1'"><i class="pcam__icon fas fa-microphone"></i></button>
				<button class="cam__btn" (click)="unmute()" *ngIf="speaker=='0'"><i class="pcam__icon fas fa-microphone-slash"></i></button>
				<button class="cam__btn" (click)="preview()" *ngIf="camera=='1'"><i class="pcam__icon fas fa-video"></i></button>
				<button class="cam__btn" (click)="unpreview()" *ngIf="camera=='0'"><i class="pcam__icon fas fa-video-slash"></i></button>
				<button class="cam__btn" (click)="endcall()"><i class="fas fa-phone-slash mx-1"></i></button>
			</div>
		</div>
	</div>
	
	<div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
</div>


    
	
	


<style>
.doctor_cam_base{
	display: inline-block;
}
.patient_cam_base{
	display: inline-block;
	width: 350px;
}

.cam__base {
    background-color: #222222;
    border-radius: 5px;
    color: #fff;
	width: 400px;
}

.cuser__name{
	font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.cam__time{
	font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.cam__cancel{
	color: #909090;
    font-size: 13px;
}

#local video{
    width: 300px !important;
    height: 100% !important;
}

.cam__icon {
    padding: 4px 10px;
}

.cam__btn{
    background-color: #ea6a6e;
    color: #fff;
    border-radius: 5px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
	margin: 1rem 2px;
	border: none;
}

.cam__svideo {
    height: 50px;
    width: 50px;
    background-color: grey;
    margin: 10px 0;
    border-radius: 5px;
}


<!-- Patient Cam styles -->

.h2l_logo{
	width: 115px;
    height: 15px;
    object-fit: contain;
}

.pca_info {
    background: transparent;
    border: 1px solid grey;
    border-radius: 5px;
    color: grey;
}

.patient__cam {
    background-color: #f5f5f5;
}

.pcam_head {
    padding: 1rem;
    background: #fff;
}

.pcam_video_panel {
    height: 350px;
    margin: 1rem;
    background-color: #171717;
    border-radius: 5px;
}

.pcam_svideo {
    height: 50px;
    width: 50px;
    background-color: #171717;
	margin: 0 10px;
	border-radius: 5px;
}

.pcam__icon {
    padding: 4px 10px;
}










.pwcam_video_panel {
    height: 350px;
    margin: 1rem;
    background-color: #fff;
    border-radius: 5px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.pwc_msg{
	color: #171717;
    font-weight: 700;
}

.pwcam_svideo{
	height: 35px;
    width: 35px;
    background-color: #171717;
    margin: 0 10px;
    border-radius: 5px;
}

.pwcam__btn{
    background-color: #ea6a6e;
    color: #fff;
    border-radius: 5px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
	margin: 1rem 2px 1rem 2rem;
	border: none;
}











.patient__wcam{
	background-color: #222222;
}

.pw_cmsg {
    background-color: grey;
    width: 300px;
    height: 200px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pwc_cmsg{
	font-weight: 700;
}

</style>